<template>
  <div class="pa-2">
    <DateTimePicker
      v-model="timestamp"
      :max-date="maxDate"
      :min-date="minDate"
      :timezone="timezone"
      :available-days="availableDays"
      :available-hours="availableHours"
      :disable-oldest="disableFirstLastButton('oldest')"
      :disable-latest="disableFirstLastButton('latest')"
      :is-loading-days="isLoadingDays"
      :is-loading-hours="isLoadingHours"
      v-bind="$attrs"
      @month-change="onMonthChange"
      @day-change="onDayChange"
      @select-oldest="onSelectOldest"
      @select-latest="onSelectLatest"
      v-on="$listeners"
    />
  </div>
</template>

<script lang="ts">
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import DateTimePicker from "@evercam/shared/components/DateTimePicker"
import Vue from "vue"
import { mapStores } from "pinia"
import { useSnapshotStore } from "@/stores/snapshots"

export default Vue.extend({
  name: "SnapshotDateTimeCalendar",
  components: {
    DateTimePicker,
  },
  props: {
    selectedCamera: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timestamp: new Date(),
      isLoadingDays: false,
      isLoadingHours: false,
      availableDays: [],
      availableHours: [],
    }
  },
  computed: {
    ...mapStores(useSnapshotStore),
    maxDate() {
      return this.$moment()
        .tz(this.selectedCamera?.timezone)
        .format("YYYY-MM-DD")
    },
    minDate() {
      return this.$moment(
        Math.min(
          this.$moment(this.snapshotStore.beforedate),
          this.$moment(this.selectedCamera?.createdAt)
        )
      ).toISOString()
    },
    timezone() {
      return this.selectedCamera?.timezone || "Europe/Dublin"
    },
  },
  async created() {
    await this.snapshotStore.updateFrames({
      camera: this.selectedCamera?.exid,
      apiKey: this.selectedCamera?.userApiKey,
      apiId: this.selectedCamera?.userApiId,
    })
  },
  methods: {
    async fetchAvailableDaysForMonth(date) {
      const m = this.$moment(date)
      const year = m.format("YYYY")
      const month = m.format("MM")

      try {
        const { days } = await EvercamApi.recordings.availableDays({
          cameraId: this.selectedCamera?.exid,
          year,
          month,
        })
        this.availableDays = days
      } catch (error) {
        this.$emit(
          "fetch-error",
          "An error occurred fetching snapshot days. Please try again and, if the problem persists, contact support.",
          error
        )
        this.$errorTracker.save(error)
      }
    },
    async fetchAvailableHoursForDay(date) {
      const m = this.$moment(date)
      const year = m.format("YYYY")
      const month = m.format("MM")
      const day = m.format("DD")

      try {
        const { hours } = await EvercamApi.recordings.availableHours({
          cameraId: this.selectedCamera?.exid,
          year,
          month,
          day,
        })

        this.availableHours = hours
      } catch (error) {
        this.$emit(
          "fetch-error",
          "An error occurred fetching snapshot days. Please try again and, if the problem persists, contact support.",
          error
        )
        this.$errorTracker.save(error)
      }
    },
    async onMonthChange(month) {
      if (!month || this.isLoadingDays) {
        this.availableDays = []

        return
      }

      this.isLoadingDays = true
      await this.fetchAvailableDaysForMonth(month)
      this.isLoadingDays = false
    },
    async onDayChange(date) {
      if (!date) {
        this.availableHours = []

        return
      }

      this.isLoadingHours = true
      await this.fetchAvailableHoursForDay(date)
      this.isLoadingHours = false
    },
    onSelectOldest() {
      if (!this.snapshotStore.beforedate) {
        return
      }
      this.timestamp = this.snapshotStore.beforedate
      this.$emit("first-frame")
    },
    onSelectLatest() {
      if (!this.snapshotStore.afterdate) {
        return
      }
      this.timestamp = this.snapshotStore.afterdate
      this.$emit("last-frame")
    },
    disableFirstLastButton(value) {
      if (!this.selectedCamera || !this.timestamp) {
        return
      }
      let timestamp = this.$moment(this.timestamp)
        .tz(this.selectedCamera?.timezone)
        .format("YYYY-MM-DDTHH:mm:ssZ")
      if (value === "oldest") {
        return timestamp === this.snapshotStore.beforedate
      }

      return timestamp === this.snapshotStore.afterdate
    },
  },
})
</script>
