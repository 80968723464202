<template>
  <div>
    <svg
      ref="gridsContainer"
      xmlns="http://www.w3.org/2000/svg"
      :viewbox="`0 0 ${imgDims.width} ${imgDims.height}`"
      :height="imgDims.height"
      :width="imgDims.width"
      :style="`opacity: ${transparency};`"
      class="grids-container"
      @mouseup="onSVGMouseUp"
      @mousemove="onSVGMouseMove"
    >
      <g v-show="bimTransparencyStore.isEditMode">
        <g
          v-for="(shape, shapeIndex) in bimTransparencyStore.shapes"
          :key="`shape-${shapeIndex}`"
        >
          <polygon
            :points="getShapePath(shape)"
            :fill="isShapeSelected(shapeIndex) ? 'blue' : '#0f09'"
            stroke="black"
            :stroke-width="isShapeSelected(shapeIndex) ? 4 : 2"
            @click="selectShape(shapeIndex)"
          />
          <circle
            v-for="(point, pointIndex) in getShapePoints(shape)"
            :key="`point-${pointIndex}`"
            :r="isShapeSelected(shapeIndex) ? 8 : 6"
            :cx="point.x"
            :cy="point.y"
            stroke="#000000"
            :stroke-width="isShapeSelected(shapeIndex) ? 4 : 2"
            fill="#fff4"
            class="shape-point"
            @mousedown="onShapeResizeStart(shapeIndex, pointIndex)"
            @mouseup="onShapeResizeEnd(pointIndex)"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { mapStores } from "pinia"
import { useBimTransparencyStore } from "@/stores/bimTransparency"
export default {
  name: "BIMTransparencyEditor",
  props: {
    imgDims: {
      type: Object,
      default: () => ({}),
    },
    transparency: {
      type: Number,
      default: 0.5,
    },
  },
  data() {
    return {
      nextPoint: [],
      targetShape: Infinity,
      targetPoint: Infinity,
      isResizing: false,
      canDraw: true,
    }
  },
  computed: {
    ...mapStores(useBimTransparencyStore),
  },
  mounted() {
    this.$addEventListener("keyup", this.handleKeyup)
  },
  methods: {
    handleKeyup(e) {
      if (e.key === "Enter") {
        this.closePolygonPath()
      }
      if (e.key === "Escape") {
        this.bimTransparencyStore.deleteSelectedShape()
      }
    },
    getDenormalizedCoords([x, y]) {
      return [
        Math.round(x * this.imgDims.width),
        Math.round(y * this.imgDims.height),
      ]
    },
    getShapePath(shape) {
      if (!shape) {
        return ""
      }

      return shape.reduce((acc, coord, index) => {
        const separator = index > 0 ? "," : ""
        const [x, y] = this.getDenormalizedCoords(coord)

        return `${acc}${separator}${x} ${y}`
      }, "")
    },
    getShapePoints(shape) {
      return shape?.map((coord) => {
        const [x, y] = this.getDenormalizedCoords(coord)

        return { x, y }
      })
    },
    onSVGMouseMove(e) {
      if (!this.bimTransparencyStore.isEditMode) {
        return
      }
      if (this.isResizing) {
        this.onShapeResize(e)

        return
      }
      this.nextPoint = [
        e.offsetX / this.imgDims.width,
        e.offsetY / this.imgDims.height,
      ]
      if (this.bimTransparencyStore.isDrawing && this.canDraw) {
        let previousPoints = this.bimTransparencyStore.shapes.slice(-1)[0]
        previousPoints =
          previousPoints?.length > 1
            ? previousPoints?.slice(0, -1)
            : previousPoints
        this.bimTransparencyStore.shapes = [
          ...this.bimTransparencyStore.shapes.slice(0, -1),
          [...previousPoints, this.nextPoint],
        ]
      }
    },
    onSVGMouseUp() {
      if (
        !this.bimTransparencyStore.isEditMode ||
        this.bimTransparencyStore.isSelecting
      ) {
        this.bimTransparencyStore.isSelecting = false
        this.bimTransparencyStore.selectedShapeIndex = Infinity

        return
      }
      if (this.isResizing || !this.canDraw) {
        return
      }
      let currentShape, previousShapes
      if (this.bimTransparencyStore.isDrawing && !this.isResizing) {
        currentShape = this.bimTransparencyStore.shapes.slice(-1)[0] || []
        previousShapes = this.bimTransparencyStore.shapes.slice(0, -1) || []
      } else {
        this.bimTransparencyStore.isDrawing = true
        currentShape = []
        previousShapes = this.bimTransparencyStore.shapes || []
      }
      this.bimTransparencyStore.shapes = [
        ...previousShapes,
        [...currentShape, this.nextPoint],
      ]
    },
    selectShape(index) {
      if (
        !this.bimTransparencyStore.isEditMode ||
        this.bimTransparencyStore.isDrawing
      ) {
        return
      }
      this.bimTransparencyStore.isSelecting = true
      this.bimTransparencyStore.selectedShapeIndex = index
    },
    isShapeSelected(shapeIndex) {
      return (
        this.bimTransparencyStore.isSelecting &&
        this.bimTransparencyStore.selectedShapeIndex === shapeIndex
      )
    },
    onShapeResizeStart(shapeIndex, pointIndex) {
      if (
        !this.bimTransparencyStore.isEditMode ||
        this.bimTransparencyStore.isDrawing
      ) {
        return
      }
      this.isResizing = true
      this.canDraw = false
      this.targetShape = shapeIndex
      this.targetPoint = pointIndex
    },
    onShapeResize(e) {
      const shape = this.bimTransparencyStore.shapes[this.targetShape]
      this.bimTransparencyStore.shapes = [
        ...this.bimTransparencyStore.shapes.slice(0, this.targetShape),
        [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...shape?.slice(0, this.targetPoint),
          [e.offsetX / this.imgDims.width, e.offsetY / this.imgDims.height],
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...shape?.slice(this.targetPoint + 1),
        ],
        ...this.bimTransparencyStore.shapes.slice(this.targetShape + 1),
      ]
    },
    onShapeResizeEnd() {
      if (
        !this.bimTransparencyStore.isEditMode ||
        this.bimTransparencyStore.isDrawing
      ) {
        return
      }
      this.isResizing = false
      this.$setTimeout(() => (this.canDraw = true))
    },
    closePolygonPath() {
      this.bimTransparencyStore.isDrawing = false
      this.bimTransparencyStore.shapes = [
        ...this.bimTransparencyStore.shapes.slice(0, -1),
        this.bimTransparencyStore.shapes.slice(-1)[0]?.slice(0, -1),
      ]
    },
  },
}
</script>

<style scoped>
.grids-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: transparent;
}
.shape-point {
  cursor: nwse-resize;
}
</style>
