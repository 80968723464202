<template>
  <ERow no-gutters class="measuring__controls px-4 py-2" justify="between">
    <ERow justify="end" :class="{ 'px-3': !bimTransparencyStore.isEditMode }">
      <v-tooltip v-if="bimTransparencyStore.isEditMode" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            small
            class="mr-3"
            v-bind="attrs"
            @mousedown="updateMask"
            v-on="on"
          >
            <v-icon>fa-save</v-icon>
          </v-btn>
        </template>
        <span>Save</span>
      </v-tooltip>

      <!-- Select mode -->
      <v-tooltip v-if="bimTransparencyStore.isEditMode" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            small
            class="mr-3"
            :color="bimTransparencyStore.isSelecting ? 'primary' : 'default'"
            v-bind="attrs"
            @mousedown="
              bimTransparencyStore.isSelecting =
                !bimTransparencyStore.isSelecting
            "
            v-on="on"
          >
            <v-icon
              :dark="bimTransparencyStore.isSelecting"
              :color="
                bimTransparencyStore.isSelecting ? 'white' : 'grey darken-3'
              "
            >
              fas fa-mouse-pointer
            </v-icon>
          </v-btn>
        </template>
        <span>Select</span>
      </v-tooltip>

      <!-- Delete -->
      <v-tooltip v-if="bimTransparencyStore.isEditMode" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            small
            class="mr-3"
            :disabled="
              bimTransparencyStore.selectedShapeIndex === Infinity ||
              !bimTransparencyStore.shapes.length
            "
            v-bind="attrs"
            @mousedown="bimTransparencyStore.deleteSelectedShape"
            v-on="on"
          >
            <v-icon color="grey darken-3">fas fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>

      <!-- Edit mode toggle -->
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            small
            :class="bimTransparencyStore.isEditMode ? 'mr-3' : 'px-2'"
            :block="!bimTransparencyStore.isEditMode"
            v-bind="attrs"
            @mousedown="toggleEditMode"
            v-on="on"
          >
            <v-icon color="grey darken-3">{{
              `fas fa-${
                bimTransparencyStore.isEditMode ? "times" : "layer-group"
              }`
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{
          bimTransparencyStore.isEditMode ? "Close" : "Open tool"
        }}</span>
      </v-tooltip>
    </ERow>
  </ERow>
</template>

<script>
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useBimTransparencyStore } from "@/stores/bimTransparency"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  name: "BIMTransparencyToolBar",
  computed: {
    ...mapStores(useBimTransparencyStore, useCameraDialogStore),
  },
  methods: {
    toggleEditMode() {
      this.bimTransparencyStore.isEditMode =
        !this.bimTransparencyStore.isEditMode
    },
    updateMask() {
      EvercamApi.layers
        .createLayer(this.cameraDialogStore.camera?.exid, {
          name: this.cameraDialogStore.camera?.exid + " BIM Mask",
          startAt: new Date().toISOString(),
          layerType: LAYER_TYPES.BIM_MASK,
          apiKey: this.cameraDialogStore.camera?.userApiKey,
          apiId: this.cameraDialogStore.camera?.userApiId,
          shapes: JSON.stringify(this.bimTransparencyStore.shapes),
        })
        .then(() => {
          this.$notifications.success("Mask updated successfully.")
        })
        .catch((error) => {
          this.$notifications.error({ text: "Updating mask failed!", error })
        })
    },
  },
}
</script>
