<template>
  <v-layout v-if="!isInitialized" class="h-100" align-center justify-center>
    <EvercamLoadingAnimation />
  </v-layout>
  <v-layout
    v-else-if="!bimCompareStore.isBimAvailable"
    min-height
    align-center
    justify-center
  >
    <v-flex xs12 sm8 md4 lg3>
      <v-card class="elevation-1">
        <v-card-text class="text-center caption">
          <p>
            BIM images not found for camera
            <b>{{ cameraDialogStore.camera.name }}</b>
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
  <v-container
    v-else
    ref="container"
    v-resize-observer="handleResize"
    fluid
    class="pa-0"
  >
    <ERow no-gutters>
      <div class="flex-grow-1">
        <div
          class="position-relative bim-compare__viewer"
          :style="imageContainerStyle"
        >
          <v-overlay :absolute="true" :value="isLoading">
            <EvercamLoadingAnimation :verify-theme-mode="false" />
          </v-overlay>
          <EZoomableImg
            ref="img"
            :src="bimCompareStore.cameraImage"
            class="camera_image"
            disabled
            :slider="false"
            :overlay-style="{ zIndex: 1 }"
            @load="handleResize"
          >
            <template #imageOverlay>
              <div>
                <v-stage
                  ref="stage"
                  :config="configKonva"
                  class="transparencyClipPath"
                  :style="{
                    opacity: bimCompareStore.transparency / 100,
                  }"
                  @mousedown="handleStageMouseDown"
                >
                  <v-layer ref="layer">
                    <v-image :config="konvaImage" />
                    <v-transformer
                      v-if="isModelMovable"
                      ref="transformer"
                      :config="{ shouldOverdrawWholeArea: true }"
                    />
                  </v-layer>
                </v-stage>
                <BimTransparencyArea
                  v-if="!isModelMovable"
                  :img-dims="configKonva"
                  :slider-val="horizontalOffset"
                  :shapes="bimTransparencyStore.shapes"
                />
                <ECompareSeparator
                  v-if="!isModelMovable"
                  :x-offset="horizontalOffset"
                />
                <v-slider
                  v-if="!isModelMovable"
                  id="slider"
                  v-model="horizontalOffset"
                  color="transparent"
                  background-color="transparent"
                  track-color="transparent"
                  track-fill-color="transparent"
                  hide-details
                />
              </div>
              <BimTransparencyEditor
                v-if="!isModelMovable && configKonva"
                :imgDims="configKonva"
              />
            </template>
          </EZoomableImg>
        </div>
        <div
          v-if="!bimCompareStore.finalView"
          ref="bimTimelineSlider"
          class="py-2 w-100"
        >
          <v-slider
            v-model="bimCompareStore.bimIndex"
            step="1"
            ticks="always"
            tick-size="4"
            :tick-labels="bimCompareStore.bimAvailableDaysLabels"
            :min="0"
            :max="bimCompareStore.bimIndexMax"
            thumb-size="8"
            thumb-color="#1976d2"
            thumb-label="always"
            hide-details
            @change="updateBimTimeline"
          >
            <template #thumb-label="{ value }">
              <p class="slider_label">
                {{ getBimTimelineLabel(value) }}
              </p>
            </template>
          </v-slider>
        </div>
      </div>
      <BimCompareSidebar
        :style="$vuetify.breakpoint.mdAndUp ? sidebarStyle : {}"
        :is-model-movable="isModelMovable"
        @enable-model-move="enableBimModelMove"
        @disable-model-move="enableBimModelMove(false)"
        @save-model-position="saveModelPosition"
        @load-model-data="renderBimData"
        @load-model-image="getNearestBimImage"
      />
    </ERow>
  </v-container>
</template>

<script>
import BimCompareSidebar from "@/components/bimCompare/BimCompareSidebar"
import BimTransparencyEditor from "@/components/bim/BimTransparencyEditor"
import BimTransparencyArea from "@evercam/shared/components/bim/BimTransparencyArea"
import { findNearestTimelineIndex } from "@evercam/shared/utils"
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useBimTransparencyStore } from "@/stores/bimTransparency"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { useBimCompareStore } from "@/stores/bimCompare"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import axios from "@evercam/shared/api/client/axios"

export default {
  components: {
    BimTransparencyEditor,
    BimTransparencyArea,
    EvercamLoadingAnimation,
    BimCompareSidebar,
  },
  asyncData({ params }) {
    useBreadcrumbStore().breadcrumbs = [
      {
        icon: "fa-solid fa-home",
        href: "/",
      },
      {
        name: "Bim",
        href: "/bim-compare",
      },
      {
        name: params.exid,
        href: `/bim-compare/${params.exid}/compare`,
      },
      {
        name: "compare",
      },
    ]
  },
  data() {
    return {
      horizontalOffset: 50,
      selectedShapeName: "",
      isModelMovable: false,
      imageContainerStyle: {},
      sidebarStyle: {},
      configKonva: {
        width: 0,
        height: 0,
      },
      previousBimImage: null,
      viewerWidth: null,
      viewerHeight: null,
      scaleY: null,
      scaleX: null,
      x: null,
      y: null,
      konvaImage: {},
      transparencyLayer: undefined,
      isInitialized: false,
    }
  },
  computed: {
    ...mapStores(
      useBimTransparencyStore,
      useSnapshotStore,
      useCameraDialogStore,
      useBimCompareStore
    ),
    isLoading() {
      return (
        this.bimCompareStore.isSnapshotLoading ||
        this.bimCompareStore.isBimImageLoading
      )
    },
  },
  watch: {
    "bimCompareStore.snapshotCurrentDate": {
      async handler(date) {
        await this.initialize()
        this.onCalendarChange(date)
      },
      immediate: true,
    },
  },
  mounted() {
    this.$addEventListener("keydown", this.moveBimModelWithArrowKeys)
  },
  methods: {
    async initialize() {
      if (this.isInitialized) {
        return
      }
      if (this.cameraDialogStore.camera?.exid !== this.$route.params.exid) {
        await this.cameraDialogStore.selectCamera(this.$route.params.exid)
      }
      await this.snapshotStore.updateFrames({
        camera: this.cameraDialogStore.camera.exid,
        apiKey: this.cameraDialogStore.camera.userApiKey,
        apiId: this.cameraDialogStore.camera.userApiId,
      })
      this.isInitialized = true
    },
    onCalendarChange(date) {
      axios.cancelRequests()
      this.getSnapshot(date)
      this.getBimMaskLayers(date)
      this.renderBimData()
    },
    handleResize() {
      const cameraImg = this.$refs.img?.$refs?.image
      if (!cameraImg) {
        return
      }
      this.configKonva.width = cameraImg.clientWidth
      this.configKonva.height = cameraImg.clientHeight
      this.konvaImage.scaleX =
        (cameraImg.clientWidth * this.scaleX) / this.viewerWidth
      this.konvaImage.scaleY =
        (cameraImg.clientHeight * this.scaleY) / this.viewerHeight
      this.konvaImage.x = (cameraImg.clientWidth * this.x) / this.viewerWidth
      this.konvaImage.y = (cameraImg.clientHeight * this.y) / this.viewerHeight

      const containerTop =
        this.$refs.container?.getBoundingClientRect()?.top || 0
      const bimTimelineSliderHeight =
        this.$refs.bimTimelineSlider?.clientHeight || 0
      this.imageContainerStyle = {
        height: `${
          window.innerHeight - containerTop - bimTimelineSliderHeight
        }px`,
      }
      this.sidebarStyle = {
        height: `${window.innerHeight - containerTop}px`,
      }
    },
    updateBimImageProperties(bimData) {
      this.viewerWidth = bimData?.viewerWidth
      this.viewerHeight = bimData?.viewerHeight
      this.scaleY = bimData?.scaleY
      this.scaleX = bimData?.scaleX
      this.x = bimData?.x
      this.y = bimData?.y
      this.konvaImage = {
        image: "",
        opacity: 1.0,
        name: "bimModel",
        x: parseFloat(bimData?.x),
        y: parseFloat(bimData?.y),
        scaleX: parseFloat(bimData?.scaleX),
        scaleY: parseFloat(bimData?.scaleY),
        rotation: parseFloat(bimData?.rotation),
        width: isNaN(bimData?.width) ? 0 : bimData?.width,
        height: isNaN(bimData?.height) ? 0 : bimData?.height,
      }
      this.bimCompareStore.layerId = bimData.id
    },
    async renderBimData() {
      try {
        this.bimCompareStore.resetBimProperties()
        const bimData = await this.bimCompareStore.fetchBimData()

        if (!bimData) {
          return
        }
        this.updateBimImageProperties(bimData)
        this.bimCompareStore.updateBimProperties(bimData)
        this.renderBimImage()
      } catch (error) {
        console.error(error)
      }
    },
    async renderBimImage() {
      if (!this.bimCompareStore.finalView && this.bimCompareStore.bimMaxDate) {
        await this.bimCompareStore.fetchBimImage()
      }

      const previousBimImage = this.previousBimImage
      if (previousBimImage) {
        previousBimImage.src = ""
        previousBimImage.onload = null
      }

      const image = new window.Image()
      image.src = this.bimCompareStore.latestBimImage
      image.width = this.konvaImage.width
      image.height = this.konvaImage.height
      image.onload = () => {
        this.konvaImage.image = image
        this.bimCompareStore.isBimImageLoading = false
        this.handleResize()
      }
      this.previousBimImage = image
    },
    getBimTimelineLabel(val) {
      return this.bimCompareStore.bimAvailableDays?.[val]
    },
    updateBimTimeline() {
      if (this.bimCompareStore.bimAvailableDays?.length) {
        this.bimCompareStore.bimDatetime =
          this.bimCompareStore.fullDates[this.bimCompareStore.bimIndex]
      }
      this.renderBimImage()
    },
    isLatestSnapshotDate(date) {
      return (
        Math.abs(
          this.$moment
            .duration(
              this.$moment(date).diff(
                this.snapshotStore.latestImageJson?.createdAt
              )
            )
            .asHours()
        ) < 1
      )
    },
    isOldestSnapshotDate(date) {
      return (
        Math.abs(
          this.$moment
            .duration(
              this.$moment(date).diff(
                this.snapshotStore.oldestImageJson?.createdAt
              )
            )
            .asHours()
        ) < 1
      )
    },
    getSnapshot(timestamp) {
      if (this.isLatestSnapshotDate(timestamp)) {
        this.bimCompareStore.cameraImage =
          this.snapshotStore.latestImageJson.data

        return
      } else if (this.isOldestSnapshotDate(timestamp)) {
        this.bimCompareStore.cameraImage =
          this.snapshotStore.oldestImageJson.data

        return
      }
      this.bimCompareStore.isSnapshotLoading = true
      EvercamApi.recordings
        .nearest(this.cameraDialogStore.camera.exid, `${timestamp}`, {
          apiKey: this.cameraDialogStore.camera?.userApiKey,
          apiId: this.cameraDialogStore.camera?.userApiId,
        })
        .then((response) => {
          if (response.snapshots.length !== 0) {
            this.bimCompareStore.cameraImage = response.snapshots[0].data
          }
        })
        .finally(() => {
          this.bimCompareStore.isSnapshotLoading = false
        })
    },
    handleStageMouseDown(e) {
      if (this.$refs.transformer) {
        if (e.target === e.target.getStage()) {
          this.selectedShapeName = ""
          this.updateTransformer()

          return
        }
        const clickedOnTransformer =
          e.target.getParent().className === "Transformer"
        if (clickedOnTransformer) {
          return
        }
        this.selectedShapeName = e.target.name()
        this.updateTransformer()
      }
    },
    moveBimModelWithArrowKeys(e) {
      const movePixel = 1,
        arrowUp = 38,
        arrowDown = 40,
        arrowRight = 39,
        arrowLeft = 37

      if (
        !this.isModelMovable ||
        !this.selectedShapeName ||
        !this.bimCompareStore.isBimAvailable ||
        this.isBimImageLoading
      ) {
        return
      }
      if (e.keyCode === arrowLeft) {
        this.konvaImage.x = this.konvaImage.x - movePixel
      } else if (e.keyCode === arrowRight) {
        this.konvaImage.x = this.konvaImage.x + movePixel
      } else if (e.keyCode === arrowUp) {
        this.konvaImage.y = this.konvaImage.y - movePixel
      } else if (e.keyCode === arrowDown) {
        this.konvaImage.y = this.konvaImage.y + movePixel
      }
      e.preventDefault()
    },
    updateTransformer() {
      const transformerNode = this.$refs.transformer.getStage()
      const stage = transformerNode.getStage()
      const selectedNode = stage.findOne("." + this.selectedShapeName)
      if (selectedNode === transformerNode.node()) {
        return
      }
      if (selectedNode) {
        transformerNode.attachTo(selectedNode)
      } else {
        transformerNode.detach()
      }
      transformerNode.getLayer().batchDraw()
    },
    enableBimModelMove(value = true) {
      this.isModelMovable = value
      this.konvaImage.draggable = value
    },
    async saveModelPosition() {
      this.enableBimModelMove(false)
      if (
        this.bimCompareStore.isBimImageLoading ||
        !this.bimCompareStore.isBimAvailable
      ) {
        this.$notifications.warn("BIM model not available.")

        return
      }
      let cameraImg = this.$refs.img?.$refs?.image
      const transformerNode = this.$refs.transformer.getStage()
      const stage = transformerNode.getStage()
      const { selectedShapeName } = this
      const selectedNode = stage.findOne("." + selectedShapeName)
      if (!selectedNode) {
        this.$notifications.warn("Resize or move BIM model first then save.")

        return
      }
      try {
        await EvercamApi.layers.updateLayer(
          this.cameraDialogStore.camera.exid,
          this.bimCompareStore.layerId,
          {
            shapes: JSON.stringify({
              x: selectedNode.attrs.x,
              y: selectedNode.attrs.y,
              scaleX: selectedNode.attrs.scaleX,
              scaleY: selectedNode.attrs.scaleY,
              rotation: selectedNode.attrs.rotation,
              width: selectedNode.attrs.width,
              height: selectedNode.attrs.height,
              viewerWidth: cameraImg?.clientWidth,
              viewerHeight: cameraImg?.clientHeight,
            }),
            apiKey: this.cameraDialogStore.camera?.userApiKey,
            apiId: this.cameraDialogStore.camera?.userApiId,
          }
        )
        this.$notifications.success("BIM model saved successfully.")
      } catch (error) {
        this.$notifications.error({
          text: "Failed to update layers!",
          error,
        })
      }
    },
    getNearestBimImage() {
      if (!this.bimCompareStore.bimDatetime || this.bimCompareStore.finalView) {
        return
      }
      this.bimCompareStore.bimIndex = findNearestTimelineIndex(
        this.bimCompareStore.bimDatetime,
        this.bimCompareStore.bimAvailableDays
      )
      this.renderBimImage()
    },
    async getBimMaskLayers(date) {
      const response = await EvercamApi.layers.getLayers(
        this.cameraDialogStore.camera?.exid,
        {
          timestamp: date,
          layerType: LAYER_TYPES.BIM_MASK,
          apiKey: this.cameraDialogStore.camera.userApiKey,
          apiId: this.cameraDialogStore.camera.userApiId,
        }
      )
      try {
        this.transparencyLayer = response
        if (
          !this.transparencyLayer.shapes ||
          new Date(date).getTime() <
            new Date(this.transparencyLayer.startAt).getTime()
        ) {
          this.bimTransparencyStore.shapes = []

          return
        }
        this.bimTransparencyStore.shapes = JSON.parse(
          this.transparencyLayer.shapes
        )
      } catch (e) {
        this.bimTransparencyStore.shapes = []
      }
    },
  },
}
</script>

<style scoped>
* {
  text-transform: none !important;
}
.transparencyClipPath {
  clip-path: url(#myClip);
}
canvas {
  pointer-events: none;
  float: left;
  display: block;
  border: none;
}
.camera_image {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.slider_label {
  position: absolute;
  width: 100px;
  text-align: center;
  background-color: #1976d2;
  bottom: -28px;
}
</style>

<style lang="scss">
.bim-compare__viewer {
  .v-overlay__content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-slider__thumb {
    width: 40px;
    left: -24px;
    cursor: ew-resize;
    border-radius: 0 !important;
    height: 100vh !important;
  }

  .v-input__slider {
    top: 50%;
    position: absolute;
    width: 100%;
    z-index: 4;
  }
}
</style>
